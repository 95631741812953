import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../../components/useFullPageLoader";
import axios from "../../../components/axios";
import { apiUrl } from "../../../components/axios";
import Swal from "sweetalert2";
import Select from "react-select"
import { useUser } from "../../../components/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { formatInputDate } from '../../../components/dateFormate'



const Profile = () => {
    const { userData } = useUser();
    const navigate = useNavigate();
    const { UserId, EnquiryId } = useParams();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [errors, setErrors] = useState({});
    const [certificateOption, setCertificateType] = useState([]);
    const [isEditing, setIsEditing] = useState(false);


    useEffect(() => {
        if(userData && userData?.UserId){
            fetchData();
        }
    }, [userData]);

    const fetchData = async () => {

        try {
            showLoader();
            const certificateType = await axios.get(`/getCertificateType`);
            const Option = certificateType.data.map((option) => ({
                label: option.Certificate_Name,
                value: option.Sr_No,
            }));
            setCertificateType(Option);

            if (userData?.UserId && EnquiryId) {
                const response = await axios.get(`/getCustomerDocuments/${EnquiryId}`);
                const res = await axios.get(`/getCustomer/${UserId ? UserId : userData?.UserId}/${EnquiryId}`);
                setCustomer(res.data);
                setCustomer((customer) => ({
                    ...customer,
                    Documents: response.data,
                }));
                //console.log(res.data);
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
     
    };




    const handleEditClick = (e) => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        fetchData();
    };


    const [customer, setCustomer] = useState({
        CustomerId: "",
        First_Name: "",
        Last_Name: "",
        Company_Name: "",
        Reference: "",
        Document_Status: "",
        Payment_Status: "",
        Documents: [],
        Mobile: "",
        Email: "",
        Address: ""
    });

    console.log(customer);

    const handleSubmit = async (event) => {
        event.preventDefault();
        debugger;
        const updatedData = {
            ...customer,
            EnquiryId: EnquiryId,
        }


        const validationErrors = {};

        if (!/^[a-zA-Z\s]+$/.test(customer.First_Name)) {
            validationErrors.First_Name = "Invalid Input";
        }
        if (!/^[a-zA-Z\s]+$/.test(customer.Last_Name)) {
            validationErrors.Last_Name = "Invalid Input";
        }
        if (!/^([6-9][0-9]{9,11})$/.test(customer.Mobile)) {
            validationErrors.Mobile = "Invalid Mobile Number";
        }

        //   if (
        // //    !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(customer.Email) &&
        //     customer.Email !== "" || null
        //   ) {
        //     validationErrors.Email = "Invalid Email Address";
        //   }

        if (Object.keys(validationErrors).length === 0) {
            showLoader();
            try {
                await axios.put(`/updateCustomer/${UserId}`, updatedData);
                Swal.fire("Success!", "Your Changes Submitted Successfully.", "success").then((result) => {
                    if (result.isConfirmed) {
                        handleCancelEdit();
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                hideLoader();
            }
        } else {
            setErrors(validationErrors);
        }
    };



    const handleDownloadAllDocuments = async () => {
        if (!customer.Documents || customer.Documents.length === 0) {
            alert('No Documents Found!!');
            return;
        }

        const zip = new JSZip();
        const promises = [];

        customer.Documents.forEach(document => {
            const fileName = document.Document_Name;
            const fileUrl = `/downloadDoc/${fileName}`;

            // Push each axios.get request promise to an array
            promises.push(
                axios.get(fileUrl, { responseType: 'arraybuffer' })
                    .then(response => {
                        zip.file(fileName, response.data, { binary: true });
                    })
                    .catch(error => {
                        console.error('Error fetching document:', error);
                    })
            );
        });

        // Wait for all requests to complete
        Promise.all(promises)
            .then(() => {
                // Generate the zip file after all requests are done
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        FileSaver.saveAs(blob, `${customer.First_Name} ${customer.Last_Name} - ${customer.Certificate_Name}.zip`);
                    })
                    .catch(error => {
                        console.error('Error generating zip file:', error);
                    });
            });
    };

    return (
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                            <h4 className="page-title">{userData?.Role ? customer?.First_Name? `${customer.First_Name} ${customer.Last_Name} - (${customer.Certificate_Name})`:"" : 'Profile'}</h4>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-3 d-none">
                                            <div className="mb-3">
                                                <label htmlFor="CustomerId" className="form-label">
                                                    Customer Id
                                                </label>
                                                <input
                                                    type="text"
                                                    id="CustomerId"
                                                    className="form-control"
                                                    placeholder="Enter Customer Id"
                                                    disabled
                                                    value={customer.CustomerId}
                                                // required
                                                // onChange={(e) =>
                                                //   setCustomer({
                                                //     ...customer,
                                                //     CustomerId: e.target.value,
                                                //   })
                                                // }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="txtFname" className="form-label">
                                                    First Name<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="txtFname"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    required
                                                    disabled={!isEditing}
                                                    value={customer.First_Name}
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            First_Name: e.target.value,
                                                        })
                                                    }
                                                />
                                                <p className="text-danger">{errors.First_Name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="txtLname" className="form-label">
                                                    Last Name<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="txtLname"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    required
                                                    disabled={!isEditing}
                                                    value={customer.Last_Name}
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            Last_Name: e.target.value,
                                                        })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Last_Name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="Company_Name" className="form-label">
                                                    Company Name<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="Company_Name"
                                                    className="form-control"
                                                    required
                                                    disabled={!isEditing}
                                                    value={customer.Company_Name}
                                                    placeholder="Enter Company Name"
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            Company_Name: e.target.value,
                                                        })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Company_Name}</p>
                                            </div>
                                        </div>
                                        <div className={customer.Quotation==='Yes'? "col-md-6":"col-md-4"}>
                                            <div className="mb-3">
                                                <label htmlFor="txtMobile" className="form-label">
                                                    Mobile<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    maxLength={10}
                                                    value={customer.Mobile}
                                                    id="txtMobile"
                                                    className="form-control"
                                                    placeholder="Enter Mobile Number"
                                                    required
                                                    disabled={!isEditing}
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const numericInput = input.replace(/[^0-9]/g, "");
                                                        setCustomer({ ...customer, Mobile: numericInput });
                                                    }}
                                                />
                                                <p className="text-danger">{errors.Mobile}</p>
                                            </div>
                                        </div>
                                        <div className={customer.Quotation==='Yes'? "col-md-6":"col-md-4"}>
                                            <div className="mb-3">
                                                <label htmlFor="txtEmail" className="form-label">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    id="txtEmail"
                                                    className="form-control"
                                                    placeholder="Enter Email Id"
                                                    disabled={!isEditing}
                                                    value={customer.Email}
                                                    onChange={(e) =>
                                                        setCustomer({ ...customer, Email: e.target.value })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Email}</p>
                                            </div>
                                        </div>
                                        <div className={customer.Quotation==='Yes'? "d-none":"col-md-4"}>
                                            <div className="mb-3">
                                                <label htmlFor="Certificate" className="form-label">
                                                    Certificate Type<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <Select
                                                    type="text"
                                                    name="Certificate"
                                                    id="Certificate"
                                                    options={certificateOption}
                                                    required
                                                    isDisabled={!isEditing }
                                                    value={customer.Certificate_Id ? {
                                                        label: customer.Certificate_Name,
                                                        value: customer.Certificate_Id,
                                                    } : {}}
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            Certificate_Id: e.value,
                                                            Certificate_Name: e.label,
                                                        })
                                                    }
                                                />

                                                <p className="text-danger">{errors.Certificate_Name}</p>
                                            </div>

                                        </div>
                                        <div className="col-md-3 d-none">
                                            <div className="mb-3">
                                                <label htmlFor="Reference" className="form-label">
                                                    Reference
                                                </label>
                                                <input
                                                    type="text"
                                                    id="Reference"
                                                    disabled={!isEditing}
                                                    value={customer.Reference}
                                                    className="form-control"
                                                    placeholder="Enter Reference"
                                                    onChange={(e) =>
                                                        setCustomer({ ...customer, Reference: e.target.value })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Reference}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="txtAddress" className="form-label">
                                                    Address<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <textarea
                                                    type="text"
                                                    id="txtAddress"
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    required
                                                    rows={3}
                                                    value={customer.Address}
                                                    disabled={!isEditing}
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            Address: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <p className="text-danger">{errors.Address}</p>
                                        </div>
                                        <div className="col-md-5 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Payment_Status">
                                                        Payment Status
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Payment_Status"
                                                        name="Payment_Status"
                                                        disabled={!isEditing}
                                                        value={customer.Payment_Status}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                Payment_Status: e.target.value,
                                                            })
                                                        }
                                                    >
                                                        <option selected value="">
                                                            --Select--
                                                        </option>
                                                        <option value="Approved">
                                                            Approved
                                                        </option>
                                                        <option value="Reject">
                                                            Reject
                                                        </option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-3" style={{ marginTop: "27px" }}>
                                        <label className="form-label" htmlFor="Payment_Status">
                                                        Payment Receipt
                                                    </label>
                                            <div className="mb-3">
                                                {customer.Payment_Doc ? (
                                                    <>
                                                        <a
                                                            style={{ color: "blue", cursor: 'pointer' }}
                                                            href={`${apiUrl}/viewPaymentDoc/${customer.Payment_Doc}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="cursor-pointer"
                                                        >
                                                            <i className="ti ti-eye"></i>
                                                        </a>
                                                        {" / "}
                                                        <a
                                                            style={{ color: "blue", cursor: 'pointer' }}
                                                            href={`${apiUrl}/downloadPaymentDoc/${customer.Payment_Doc}`}
                                                            download={true}
                                                            className="cursor-pointer"
                                                        >
                                                            <i className="ti ti-download"></i>
                                                        </a>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="ti ti-eye-off"></i> / <i className="ti ti-download-off"></i>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-5 d-none">
                                            <div className="mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="Document_Status">
                                                        Document Status
                                                    </label>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        id="Document_Status"
                                                        name="Document_Status"
                                                        value={customer.Document_Status}
                                                        disabled={!isEditing}

                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                Document_Status: e.target.value,
                                                            })
                                                        }
                                                    >
                                                        <option selected value="">
                                                            --Select--
                                                        </option>
                                                        <option value="Approved">
                                                            Approved
                                                        </option>
                                                        <option value="Reject">
                                                            Reject
                                                        </option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 d-none" style={{ marginTop: "27px" }}>
                                            <div className="mb-3">
                                                {customer.Documents.length > 0 ? (
                                                    <>
                                                        {/* <a style={{ color: "blue", cursor: 'pointer' }} href={`/viewDoc/` + customer.Documents.Document_Name} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                                                    <i class="ti ti-eye"></i>
                                                                </a> / */}
                                                        <a style={{ color: "blue", cursor: 'pointer' }} onClick={handleDownloadAllDocuments} className="cursor-pointer">
                                                            <i class="ti ti-download"></i>
                                                        </a></>
                                                ) : (
                                                    <> {/*<i class="ti ti-eye-off"></i> /  */}
                                                        <i class="ti ti-download-off"></i></>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            {!isEditing && (
                                                <button
                                                    className="btn btn-primary btn-sm mt-2"
                                                    onClick={handleEditClick}
                                                >
                                                    Edit
                                                </button>
                                            )}{" "}
                                            &nbsp;

                                            {isEditing && (
                                                <button
                                                    className="btn btn-primary btn-sm mt-2"
                                                    onClick={

                                                        handleCancelEdit
                                                    }
                                                >
                                                    Close
                                                </button>
                                            )}
                                            &nbsp;
                                            {isEditing && (
                                                <button className="btn btn-primary btn-sm mt-2" type="submit">
                                                    Save Changes
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {loader}
                    </div>
                </div>   </div>   </div>
    );
};
export default Profile;
