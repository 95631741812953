import * as React from "react";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import { useUser } from "../../components/UserContext";
import "jspdf-autotable";
import { useParams, useNavigate, Link } from "react-router-dom";
import useFullPageLoader from "../../components/useFullPageLoader";
import PaymentModal from "./PaymentModal"
import UploadDocument from "./UploadDocument"
import Swal from "sweetalert2";

const EnquiryCustomer = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [isModal1, setIsModal1] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [indexValue, setIndex] = useState();
  const [installmentStatus, setInstallmentStatus] = useState({});

  const showModal = (index) => {
    setIndex(index);
    setIsModal1(true);
  };
  const closed = () => {
    setIsModal1(false);
    loadStaffData();
  };

  const showModal2 = (index) => {
    setIndex(index);
    setIsModal2(true);
  };
  const closed2 = () => {
    setIsModal2(false);
    loadStaffData();
  };

  useEffect(() => {
    if (userData?.UserId) {
      loadStaffData();
    }
  }, [userData]);

  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getEnquiries/${userData?.UserId}`);
      console.log(result.data);
      setStaffData(result.data);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const checkInstallment = async (enqId) => {
    if (enqId) {
      const result = await axios.get(`/getInstallment/${enqId}`);
      setInstallmentStatus(prevState => ({
        ...prevState,
        [enqId]: result.data.length > 0
      }));
    }
  };

  useEffect(() => {
    data.forEach(item => {
      checkInstallment(item.Sr_No);
    });
  }, [data]);

  const handleSubmit = async(Sr_No, item) => {
    const validationErrors = {};

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
     await axios
        .put(`/approveQuotation/${Sr_No}`, item )
        .then((res) => {
            Swal.fire(
                "Success!",
                "Quotation Approved Successfully!!",
                "success")
                .then(
            (result) => {
              if (result.isConfirmed) {
                loadStaffData();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          //error("Server is Busy!!");
          Swal.fire({
            title: "Error",
            text: "Failed to Approve Quotation. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    } 
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">View All Your Enquiries</h4>
          </div>
          <div className="card">
            <div className="card-body">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} className="col-md-12 mb-3" style={{ padding: '4px', border: "1px solid rgb(138 148 173 / 76%)", borderRadius: '5px' }}>
                    <h5
                      className="mb-3 text-uppercase p-2"
                      style={{
                        backgroundColor: "rgb(242, 242, 247)",
                        fontSize: "15px",
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span className=''>Certificate Name : {item.Certificate_Name}</span>
                      <span
                        className={`badge ${item.Status === "Active"
                          ? "bg-label-success"
                          : item.Status === "Pending"
                            ? "bg-label-warning"
                            : "bg-label-primary"
                          } me-1`}
                      >
                        {item.Status}
                      </span>
                      {item?.Upload_Certificate && (
                        <span> View/Download :{"  "}
                          {item.Upload_Certificate ? (
                            <>
                              <a
                                style={{ color: "blue", cursor: "pointer" }}
                                href={`${apiUrl}/viewCertificate/` + item.Upload_Certificate}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cursor-pointer"
                              >
                                <i className="ti ti-eye"></i>
                              </a>
                              /
                              <a
                                style={{ color: "blue", cursor: "pointer" }}
                                href={`${apiUrl}/downloadCetificate/` + item.Upload_Certificate}
                                download={true}
                                target="_blank"
                                className="cursor-pointer"
                              >
                                <i className="ti ti-download"></i>
                              </a>
                            </>
                          ) : (
                            <>
                              <i className="ti ti-eye-off"></i> / <i className="ti ti-download-off"></i>
                            </>
                          )}
                        </span>
                      )}
                    </h5>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        {installmentStatus[item.Sr_No] && (
                          <button className={'btn btn-primary mx-2'} onClick={() => navigate(`/invoice/${item.Sr_No}`)}>
                            Invoice / Payment
                          </button>
                        )}
{(item.Send_Quotation !== null && item.Quotation ==='No') &&(
  <button className={'btn btn-primary mx-2'} onClick={()=>{
    handleSubmit(item.Sr_No, item);
  }}>
                       Approve Quotation 
                          </button>
)}
                  
{item.Quotation ==='Yes' &&( <button className="btn btn-primary mx-2" disabled={item.Document_Status === 'Inprocess' || item.Document_Status === 'Approved'} onClick={() => showModal2(item)}>
                          {item.Document_Status === 'Reject' ? 'Reupload Document' : "Upload Document"}
                        </button>)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12 text-center">
                  <h5>No Record Found!!</h5>
                </div>
              )}
            </div>
          </div>
        </div>
        {isModal1 && (
          <PaymentModal
            open={isModal1}
            onClose={closed}
            updateData={indexValue}
          />
        )}
        {isModal2 && (
          <UploadDocument
            open={isModal2}
            onClose={closed2}
            updateData={indexValue}
            Role={'Customer'}
          />
        )}
        {loader}
      </div>
    </div>
  );
};
export default EnquiryCustomer;
