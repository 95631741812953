import React, { useEffect } from "react";
import { useState } from "react";
import axios from "./axios";
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link, Outlet, useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeCustomerPassword from "./ChangeCustomerPassword";
import { useUser } from "./UserContext";
import { apiUrl } from "./axios";
import useFullPageLoader from "./useFullPageLoader";
import $ from "jquery";
import UpdateCustomerProfile from "./UpdateCustomerProfile";
import Apply from "./Apply";

const Dashboard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { setData } = useUser();
  const [userData, setUserData] = useState(null);
  const [IsModal, setIsModal] = useState(null);
  const [isModal2, setIsModal2] = useState(false);
  const [isModal3, setIsModal3] = useState(false);

  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  useEffect(() => {
    showLoader();
    axios.get(`/dashboard`).then((res) => {
      //console.log(res.data.Success);
      if (res.data.Success === "Success") {
        const userData = {
          UserId: res.data.UserId? res.data.UserId:res.data.Sr_No,
          Role: res.data.Role ? res.data.Role : null,
          First_Name: res.data.First_Name,
          Last_Name: res.data.Last_Name,
          Email: res.data.Email,
          Org_Id: res.data.Org_Id ? res.data.Org_Id : null,
          Status: res.data.Status,
          Username: res.data.Username,
          Password: res.data.Password,
          Mobile: res.data.Mobile
        };
        hideLoader();
        setUserData(userData);
        setData(userData);
        console.log(userData);
      } 
      else {
        navigate("/login");
      }
    });
  }, []);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleLogout = () => {
    axios
      .get(`/logout`)
      .then((res) => {
        setUserData(null);
        setData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };

  const handleCustomerLogout = () => {
    axios
      .get(`/logoutCustomer`)
      .then((res) => {
        setUserData(null);
        setData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };


  useEffect(() => {
    const handleSidebarToggle = () => {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
    };
    $("#sidebarToggle, #sidebarToggleTop").on("click", handleSidebarToggle);
    return () => {
      $("#sidebarToggle, #sidebarToggleTop").off("click", handleSidebarToggle);
    };
  }, []);


  const showModal2 = () => {
    setIsModal2(true);
  };
  const closed2 = () => {
    setIsModal2(false);
    window.location.reload();
  };


  const showModal3 = () => {
    setIsModal3(true);
  };
  const closed3 = () => {
    setIsModal3(false);
    window.location.reload();
  };

  return (
    <div className="layout-wrapper layout-content-navbar  ">
      <div className="layout-container">

        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
          <div className="app-brand demo ">
            <a href="/" className="app-brand-link mt-2 mb-2"  style={{ textAlign: 'center', width: '100%', padding: '0', display: 'block', margin: '0' }}>
              <img
                id="logoimg"
                className="brand-logo border-1"
                src={`/assets/img/logo.png`}
                style={{  width: '150px' }}
              />
            </a>

            <a className="layout-menu-toggle menu-link text-large ms-auto">
              {/* <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
              <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i> */}
            </a>
          </div>
          <div className="menu-inner-shadow"></div>


          <ul className="menu-inner py-1">
            {userData?.Role ? (<>
              <li className="menu-item">
                <Link to="" className="menu-link">
                  <i className="menu-icon tf-icons ti ti-layout-dashboard"></i>
                  <div data-i18n="Dashboards">Dashboard</div>
                </Link>
              </li>

              <li className="menu-item">
                <Link to="/viewUser" className="menu-link">
                  <i className="menu-icon tf-icons ti ti-users"></i>
                  <div data-i18n="User">Admin/Staff</div>
                </Link>
              </li>

              <li className="menu-item">
                <Link to="/viewCustomers" className="menu-link">
                <i className="menu-icon tf-icons ti ti-users-group"></i>
                  <div data-i18n="ViewCustomers"> Customers</div>
                </Link>
              </li>

              <li className="menu-item">
                <Link to="/viewEnquiry" className="menu-link">
                  <i className="menu-icon tf-icons ti ti-file-text"></i>
                  <div data-i18n="Customer"> Enquiries</div>
                </Link>
              </li>

              <li className="menu-item mb-1">
                <a className="menu-link collapsed cursor-pointer menu-toggle" data-bs-toggle="collapse" data-bs-target="#components-collapse" aria-expanded="false" aria-current="true">
                  <i className="menu-icon tf-icons ti ti-settings"></i>
                  <div data-i18n="Setting">Settings</div>
                </a>
                <div className="collapse" id="components-collapse" style={{ backgroundColor: 'rgb(243 237 237 / 34%)' }}>
                  <ul className="list-unstyled fw-normal pb-1 small" style={{ paddingLeft: "20px" }}>
                    <li className="menu-item">
                      <Link to="/certificateType" className="menu-link">
                        <i className="menu-icon tf-icons ti ti-file-certificate"></i>
                        <div data-i18n="CertificateType">Certificate Type</div>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/docType" className="menu-link">
                        <i className="menu-icon tf-icons ti ti-clipboard-text"></i>
                        <div data-i18n="DocumentType">Document Type</div>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/certificateDocument" className="menu-link">
                        <i className="menu-icon tf-icons ti ti-file-orientation"></i>
                        <div data-i18n="CertificateDocument">Certificate-Document</div>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/bank" className="menu-link">
                        <i className="menu-icon tf-icons ti ti-building-bank"></i>
                        <div data-i18n="CertificateDocument">Bank</div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li></>
            ) : (
              <>
                <li className="menu-item">
                  <Link to="" className="menu-link">
                  <i className="menu-icon tf-icons ti ti-clipboard-text"></i>
                    <div data-i18n="Applications">Your Applications</div>
                  </Link>
                </li>
                {/* <li className="menu-item">
                  <Link to="/customerProfile" className="menu-link">
                    <i className="menu-icon tf-icons ti ti-user-circle"></i>
                    <div data-i18n="Profile">Profile</div>
                  </Link>
                </li> */}
              </>
            )}
          </ul>

        </aside>

        <div className="layout-page">

          {/* <!-- Navbar --> */}
          <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
              <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i className="ti ti-menu-2 ti-sm"></i>
              </a>
            </div>

            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        
              <ul className="navbar-nav flex-row align-items-center ms-auto">
              {!userData?.Role && (<button className="btn btn-primary" onClick={showModal3}>Apply</button>)}
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <a className="nav-link dropdown-toggle hide-arrow d-flex align-items-center"  data-bs-toggle="dropdown" style={{cursor:"pointer"}}>
                    <div className="avatar avatar-online me-2">
                      <img src="/assets/img/avatar.png" alt="" className="h-auto rounded-circle" />
                    </div>
                    <span className="align-middle">{`${userData?.First_Name ? userData?.First_Name : ''} ${userData?.Last_Name ? userData?.Last_Name : ""}`}</span>
                  </a>

                  <ul className="dropdown-menu dropdown-menu-end">
                    {!userData?.Role && (
                      <li>
                        <Link className="dropdown-item" onClick={() => showModal2()}>
                          <i className="ti ti-user-circle me-2 ti-sm"></i>
                          <span className="align-middle">Profile</span>
                        </Link>
                      </li>

                    )}

                    <li>
                      <Link className="dropdown-item" onClick={() => { handleOpenChangePasswordModal(); setIsModal(true); }}>
                        <i className="ti ti-key me-2 ti-sm"></i>
                        <span className="align-middle">Change Password</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" onClick={userData?.Role ? handleLogout : handleCustomerLogout}>
                        <i className="ti ti-logout me-2 ti-sm"></i>
                        <span className="align-middle">Log Out</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
             
            </div>
          </nav>
          {/* <!-- / Navbar -->
            <!-- Content wrapper --> */}
          <div className="content-wrapper">
            {/* <!-- Content --> */}

            <div className="container-xxl flex-grow-1 container-p-y">

              <Outlet />

            </div>


            <div className="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>


      {/* <!-- Overlay --> */}
      <div className="layout-overlay layout-menu-toggle"></div>


      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div className="drag-target"></div>
      {IsModal && (
        userData?.Role ? (
          <ChangePasswordModal
            open={isChangePasswordModalOpen}
            onClose={handleCloseChangePasswordModal}
            UserId={userData?.UserId}
            Role={userData?.Role}
          />
        ) : (
          <ChangeCustomerPassword
            open={isChangePasswordModalOpen}
            onClose={handleCloseChangePasswordModal}
            UserId={userData?.UserId}
          />
        )
      )}
      {isModal2 && (
        <UpdateCustomerProfile
          open={isModal2}
          onClose={closed2}
        />
      )}
      {isModal3 && (
        <Apply
          open={isModal3}
          onClose={closed3}
          customerData={{}}
        />
      )}
      {loader}
    </div>
  );
};

export default Dashboard;
