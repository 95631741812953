import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../components/useFullPageLoader";
import { useUser } from "../../components/UserContext";
import axios from "../../components/axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { apiUrl } from "../../components/axios";

const indianStates = [
  { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
  { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
  { value: 'Assam', label: 'Assam' },
  { value: 'Bihar', label: 'Bihar' },
  { value: 'Chhattisgarh', label: 'Chhattisgarh' },
  { value: 'Goa', label: 'Goa' },
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
  { value: 'Jharkhand', label: 'Jharkhand' },
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Manipur', label: 'Manipur' },
  { value: 'Meghalaya', label: 'Meghalaya' },
  { value: 'Mizoram', label: 'Mizoram' },
  { value: 'Nagaland', label: 'Nagaland' },
  { value: 'Odisha', label: 'Odisha' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Sikkim', label: 'Sikkim' },
  { value: 'Tamil Nadu', label: 'Tamil Nadu' },
  { value: 'Telangana', label: 'Telangana' },
  { value: 'Tripura', label: 'Tripura' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'Uttarakhand', label: 'Uttarakhand' },
  { value: 'West Bengal', label: 'West Bengal' },
  // Union Territories
  { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
  { value: 'Chandigarh', label: 'Chandigarh' },
  { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
  { value: 'Lakshadweep', label: 'Lakshadweep' },
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Puducherry', label: 'Puducherry' },
  { value: 'Ladakh', label: 'Ladakh' },
  { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
];


const UpdateUser = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    (async () => {
      showLoader();
      const result = await axios.get(`/getCustomerById/${Sr_No}`);
      setCustomer(result.data);
      //console.log(result.data);
      hideLoader();
    })();
  }, []);



  const handleSubmit = (event) => {
    event.preventDefault();

    const Updatedcustomer = {
      ...customer,
      Username: customer?.Mobile,
    };

    const validationErrors = {};

    if (!/^[a-zA-Z\s]+$/.test(customer.First_Name)) {
      validationErrors.First_Name = "Invalid Input";
    }
    if (!/^[a-zA-Z\s]+$/.test(customer.Last_Name)) {
      validationErrors.Last_Name = "Invalid Input";
    }
    if (!/^([6-9][0-9]{9,11})$/.test(customer.Mobile)) {
      validationErrors.Mobile = "Invalid Mobile Number";
    }

    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(customer.Email) &&
      customer.Email !== ""
    ) {
      validationErrors.Email = "Invalid Email Address";
    }

    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(customer.Email) &&
      customer.Alt_Email !== ""
    ) {
      validationErrors.Alt_Email = "Invalid Alt Email Address";
    }


    const formdata = new FormData();
    formdata.append("CustomerId", Updatedcustomer.CustomerId);
    formdata.append("Username", Updatedcustomer.Username);
    formdata.append("Password", Updatedcustomer.Password);
    formdata.append("First_Name", customer.First_Name);
    formdata.append("Last_Name", customer.Last_Name);
    formdata.append("Company_Name", customer.Company_Name);
    formdata.append("Reference", customer.Reference);
    formdata.append("Other_Reference", customer.Other_Reference);
    formdata.append("Remarks", customer.Remarks);
    formdata.append("DOB", customer.DOB);
    formdata.append("Certificate_Id", customer.Certificate_Id);
    formdata.append("Certificate_Name", customer.Certificate_Name);
    formdata.append("Gender", customer.Gender);
    formdata.append("Mobile", customer.Mobile);
    formdata.append("Email", customer.Email);
    formdata.append("Address", customer.Address);
    formdata.append("Status", customer.Status);
    formdata.append("State", customer.State);
    formdata.append("Alt_Email", customer.Alt_Email);
    formdata.append("GST_No", customer.GST_No);
    formdata.append("District", customer.District);
    formdata.append("City", customer.City);
    formdata.append("VisitingCardFront", customer.VisitingCardFront);
    formdata.append("VisitingCardBack", customer.VisitingCardBack);



    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      axios
        .put(`/updateCustomerDetails/${Sr_No}`, formdata)
        .then((res) => {
          Swal.fire("Success!", "Customer Updated.", "success").then(
            (result) => {
              if (result.isConfirmed) {
                // navigate("/login")
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            title: "Error",
            text: "Failed to Update Customer. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Update Customer</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <form method="post" id="contactForm" name="contactForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtFname" className="col-form-label">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtFname"
                        className="form-control"
                        placeholder="Enter First Name"
                        required
                        value={customer.First_Name}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            First_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.First_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtLname" className="col-form-label">
                        Last Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtLname"
                        className="form-control"
                        placeholder="Enter Last Name"
                        required
                        value={customer.Last_Name}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Last_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Last_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Company_Name" className="col-form-label">
                        Company Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        id="Company_Name"
                        className="form-control"
                        placeholder="Enter Company Name"
                        value={customer.Company_Name}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Company_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Company_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtMobile" className="col-form-label">
                        Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={customer.Mobile}
                        id="txtMobile"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        required
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setCustomer({ ...customer, Mobile: numericInput });
                        }}
                      />
                      <p className="text-danger">{errors.Mobile}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtEmail" className="col-form-label">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email" required
                        id="txtEmail"
                        value={customer.Email}
                        className="form-control"
                        placeholder="Enter Email Id"
                        onChange={(e) =>
                          setCustomer({ ...customer, Email: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Email}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Alt_Email" className="col-form-label">
                        Alt  Email
                      </label>
                      <input
                        type="email"
                        id="Alt_Email"
                        className="form-control"
                        placeholder="Enter Alt Email Id"
                        value={customer.Alt_Email}
                        onChange={(e) =>
                          setCustomer({ ...customer, Alt_Email: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Alt_Email}</p>
                    </div>
                  </div>
          
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="GST_No" className="col-form-label">
                        GST No
                      </label>
                      <input
                        type="text"
                        id="GST_No"
                        className="form-control"
                        placeholder="Enter GST No"
                        maxLength={15}
                        value={customer.GST_No}
                        onChange={(e) =>
                          setCustomer({ ...customer, GST_No: e.target.value.toUpperCase() })
                        }
                      />
                      <p className="text-danger">{errors.GST_No}</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Reference" className="col-form-label">
                        Reference<span style={{ color: "red" }}>*</span>
                      </label>
                      <select className="form-select" value={customer.Reference || ""} required onChange={(e) =>
                        setCustomer({ ...customer, Reference: e.target.value, Other_Reference: ""  })
                        
                      } >
                        <option disabled label="Select Reference" value=""></option>
                        <option label="Facebook" value="Facebook"></option>
                        <option label="Instagram" value="Instagram"></option>
                        <option label="Google" value="Google"></option>
                        <option label="LinkedIn" value="LinkedIn"></option>
                        <option label="Other" value="Other"></option>
                      </select>
                      <p className="text-danger">{errors.Reference}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Reference" className="col-form-label">
                        Mention Reference<span style={{ color: "red" }}>*</span>
                      </label>
                      <input required={customer.Reference === "Other" ? true : false}
                        disabled={customer.Reference === "Other" ? false : true}
                        type="text"
                        id="Reference"
                        value={customer.Other_Reference}
                        className="form-control"
                        placeholder="Other Reference"
                        onChange={(e) =>
                          setCustomer({ ...customer, Other_Reference: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Reference}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="City" className="col-form-label">
                        City
                      </label>
                      <input

                        type="text"
                        id="City"
                        value={customer.City}
                        className="form-control"
                        placeholder="Enter City"
                        onChange={(e) =>
                          setCustomer({ ...customer, City: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.City}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="District" className="col-form-label">
                        District
                      </label>
                      <input
                        type="text"
                        id="District"
                        value={customer.District}
                        className="form-control"
                        placeholder="Enter District"
                        onChange={(e) =>
                          setCustomer({ ...customer, District: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.District}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label htmlFor="State" className="col-form-label">
                        State<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        required
                        value={customer.State ? { value: customer.State, label: customer.State } : null}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? 'white' : 'black',
                            backgroundColor: state.isFocused ? 'blue' : 'white',
                            '&:hover': {
                              backgroundColor: 'blue',
                              color: 'white',
                            },
                          }),
                        }}
                        options={indianStates}
                        onChange={(e) => {
                          setCustomer({ ...customer, State: e.value })
                        }}
                        placeholder="Select a state"
                      />


                    </div>
                  </div>


                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="Address" className="col-form-label">
                        Address
                      </label>
                      <textarea
                        type="text"
                        id="Address"
                        className="form-control"
                        placeholder="Enter Address..."
                        rows="2"
                        value={customer.Address}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Address: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Address}</p>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="Remarks" className="col-form-label">
                        Remarks/Requirements
                      </label>
                      <textarea
                        type="text"
                        id="Remarks"
                        className="form-control"
                        placeholder="Enter Remarks..."
                        value={customer.Remarks}
                        rows="2"
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Remarks: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Remarks}</p>
                  </div>

                  <div className="col-md-5">
                    <div className="mb-3">
                      <label htmlFor="VisitingCardFront" className="col-form-label">
                        Visiting Card (Front)
                      </label>
                      <input
                        type="file"
                        id="VisitingCardFront"
                        className="form-control"
                        //placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, VisitingCardFront: e.target.files[0] })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 mt-4">
                    <>

                      {customer.VisitingCardFront ? (<>

                        <a
                          style={{ color: "blue", cursor: 'pointer' }}
                          href={`${apiUrl}/downloadVCF/${customer.VisitingCardFront}`}
                          download={true}
                          className="cursor-pointer"
                        >
                          <i className="ti ti-download"></i>
                        </a>
                      </>
                      ) : (
                        <>
                          {/* <i className="ti ti-eye-off"></i> /  */}
                          <i className="ti ti-download-off"></i>
                        </>
                      )}

                    </>
                  </div>
                  <div className="col-md-5">
                    <div className="mb-3">
                      <label htmlFor="VisitingCardBack" className="col-form-label">
                        Visiting Card (Back)
                      </label>
                      <input
                        type="file"
                        id="VisitingCardBack"
                        className="form-control"
                        //placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, VisitingCardBack: e.target.files[0] })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 mt-4">
                    <>
                      {customer.VisitingCardBack ? (
                        <>

                          <a
                            style={{ color: "blue", cursor: 'pointer' }}
                            href={`${apiUrl}/downloadVCF/${customer.VisitingCardBack}`}
                            download={true}
                            className="cursor-pointer"
                          >
                            <i className="ti ti-download"></i>
                          </a>
                        </>
                      ) : (
                        <>
                          {/* <i className="ti ti-eye-off"></i> /  */}
                          <i className="ti ti-download-off"></i>
                        </>
                      )}
                    </>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    <button type="submit" className="btn btn-primary " style={{ height: "38px", marginBottom: "10px" }}>
                      Update
                    </button><br />

                  </div>
                </div>
              </form>

            </div>
          </div>
          {loader}
        </div>
      </div>
    </div>
  );
};
export default UpdateUser;
