import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const UploadCertificate = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    console.log(updateData);

    const [customer, setCustomer] = useState({
        Email_Body:""
    });
    
    const loadEnquiry = async () => {
        showLoader();
        try {
          const result = await axios.get(`/getCertificateType/${updateData?.Certificate_Id}`);
          let data = result.data;
          setCustomer({...data,
            Customer_Id: updateData.Sr_No,
            Email_Body: data.Email_Body
            .replace('@Consultation', updateData.Consultation || '')
            .replace('@Government', updateData.Government || '')
          });
          hideLoader();
        } catch (error) {
          hideLoader();
          console.error("Error fetching data: ", error);
        }
      };
      useEffect(() => {
        if (updateData.Certificate_Id) {
          loadEnquiry();
        }
      }, [updateData]);


    console.log(customer)

    const handleClose = () => {
        setShow(false);
        onClose();
    };
    
    const handleSubmit = async(event) => {
      event.preventDefault();
  
      const validationErrors = {};
      
      if (!customer.Email_Body.trim()) {
          validationErrors.Email_Body = "Message body field is required.";
      }
  
      if (Object.keys(validationErrors).length === 0) {
          if (updateData?.Quotation_Preview === 'Yes') {
              const confirmation = await Swal.fire({
                  title: 'Are you sure?',
                  text: "Do you want to resend the quotation?",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, resend it!',
                  cancelButtonText: 'No, cancel!',
                  reverseButtons: true
              });
  
              if (!confirmation.isConfirmed) {
                  return; // If user cancels, exit the function
              }
          }
  
          showLoader();
          await axios
              .post(`/sendQuotation/${updateData?.Enquiry_Sr_No}/${updateData?.Sr_No}/${updateData?.Certificate_Name}`, customer)
              .then((res) => {
                  Swal.fire(
                      "Success!",
                      "Quotation Sent Successfully!!",
                      "success"
                  ).then((result) => {
                      if (result.isConfirmed) {
                          handleClose();
                      }
                  });
                  hideLoader();
              })
              .catch((err) => {
                  Swal.fire({
                      title: "Error",
                      text: "Failed to send Quotation. Please try again later.",
                      icon: "error",
                      confirmButtonText: "OK",
                  });
                  hideLoader();
              });
      } else {
          setErrors(validationErrors);
      }
  };
  

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Quotation Preview - ({updateData.Certificate_Name||""})</h5>
                    <div className="col-md-6 text-end d-none">
                      
                    </div>
                </div>
                <hr className="p-0 mb-1" />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-md-12">
                <div className="mb-4">
                <label htmlFor="Email_Body" className="form-label">
                     Email Body<span style={{ color: "red" }}>*</span>
                    </label>
                <CKEditor
                editor={ClassicEditor}
                data={customer.Email_Body||""}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setCustomer({
                        ...customer,
                        Email_Body: data
                    });
                    console.log(data);
                }}
            />
             {errors && (
                  <p style={{ color: "red" }}>{errors.Email_Body}</p>
                )}
                </div></div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                            <button type="submit" className="btn btn-md btn-primary ">
                           {updateData?.Quotation_Preview==='Yes'? "Resend" : "Send"} 
                            </button>
                        </div>
                    </div>
                </form>

                {loader}
            </Modal.Body>

            {/* <div className="text-danger p-3">
                Note: Upload documents less than 2MB in size and please ensure that the files are in jpg, jpeg, png, doc, docx, xls, xlsx or pdf formats.
            </div> */}

        </Modal>
    );
}
export default UploadCertificate;
