import { Button, Modal, Box } from "@mui/material";
import PasswordTextField from "./PasswordTextField";
import React, { useState, useEffect } from "react";
import { useUser } from "./UserContext";
import axios from './axios';
import Swal from "sweetalert2";

const ChangePasswordModal = ({ open, onClose, UserId }) => {
  const { userData } = useUser();
  const [oldPassword, setOldPassword] = useState("");
  const [getOldPassword, setgetOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");

  const handleClose = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setError("");
    onClose();
  };

  const handleSave = () => {
    if (newPassword !== confirmNewPassword) {
      setError("New password and confirm new password do not match.");
    } else if (oldPassword !== getOldPassword) {
      setError("Current password is incorrect.");
    } else {
      setError("");

      axios.put(`/changeCustomerPassword/${UserId}`, {
          newPassword: newPassword,
        })
        .then((response) => {
          Swal.fire("Password!", "Your password has been changed.", "success");
          handleClose();
        })
        .catch((error) => {
          // Handle errors from the server, including the 400 error for a missing new password
          if (error.response) {
            setError(error.response.data.error);
          } else {
            setError("An error occurred while changing the password.");
          }
        });
    }
  };

  useEffect(() => {
    if (UserId) {
      axios
        .get(`/getCustomerPassword?Sr_No=${UserId}`)
        .then((res) => {
          const data = res.data;
         //console.log(data);
          const formattedEmployee = {
            ...data,
            Password: data.Password,
          };
          setgetOldPassword(formattedEmployee.Password);
        });
    }
  }, [UserId, handleSave]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
        <img 
            src="/assets/img/Change-Password.png"
            alt="ChangePassword"
            style={{ width: "70px", height: "70px", marginBottom: "20px" }}
          />
          <h4 className="mb-4" style={{ marginLeft: "10px" }}>
            <b>Change Password</b>
          </h4>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="Aadhar_No">
            Current Password
          </label>
          <PasswordTextField
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="Aadhar_No">
            New Password
          </label>
          <PasswordTextField
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="Aadhar_No">
            Confirm New Password
          </label>
          <PasswordTextField
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button className="btn btn-primary" onClick={handleSave} sx={{ mt: 2 }}>
          Save
        </button>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
