import React from "react";
import "./App.css";
import Dashboard from "./components/dashboard";
import PageNotFound from "./components/404";
import Login from "./components/Login";
import HomePage from "./pages/Home/HomePage";
import AddUser from "./pages/User/AddUser";
import UpdateUser from "./pages/User/updateUser";
import ViewUser from "./pages/User/ViewUser";
import DocType from "./pages/Setting/DocType";
import CertificateType from "./pages/Setting/CertificateType";
import Bank from "./pages/Setting/Bank";
import { Routes, Route } from "react-router-dom";
import AddCustomer from "./pages/Customer/AddCustomer";
import UpdateCustomer from "./pages/Customer/UpdateCustomer";
import ViewCustomer from "./pages/Customer/ViewEnquirys";
import CertificateDocument from "./pages/Setting/BindCertificateDocument";
import CustomerProfile from "./pages/Customer/Profile/Profile";
import SetInstallment from "./pages/Customer/SetInstallment";
import Payment from "./pages/Customer/Payment";
import ViewCustomers from "./pages/Customer/ViewCustomer";
import Invoice from "./pages/Customer/Invoice";




function App() {
  return (

    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route path="" element={<HomePage />} />
        <Route path="/viewCustomers" element={<ViewCustomers />} />
        <Route path="/viewUser" element={<ViewUser />} />
        <Route path="/updateUser/:UserId" element={<UpdateUser />} />
        <Route path="/addUser" element={<AddUser />} />
        <Route path="/docType" element={<DocType />} />
        <Route path="/certificateType" element={<CertificateType />} />
        <Route path="/bank" element={<Bank />} />
        <Route path="/viewEnquiry" element={<ViewCustomer />} />
        <Route path="/viewEnquiry/:condition" element={<ViewCustomer />} />
        <Route path="/viewEnquiry/:Customer_Id/:Company_Name" element={<ViewCustomer />} />
        <Route path="/updateCustomer/:Sr_No" element={<UpdateCustomer />} />
        <Route path="/certificateDocument" element={<CertificateDocument />} />
        {/* <Route path="/customerProfile/:EnquiryId" element={<CustomerProfile />} /> */}
        <Route path="/customerProfile/:UserId/:EnquiryId" element={<CustomerProfile />} />
        <Route path="/setInstallment/:Enq_Id" element={<SetInstallment />} />
        <Route path="/makePayments/:Enq_Id" element={<Payment />} />
        <Route path="/invoice/:Enq_Id" element={<Invoice />} />
        <Route path="/invoice/:Enq_Id/:Payment_Id/:Installment_Id/:logId" element={<Invoice />} />
        <Route path="/invoice/:Enq_Id/:Perfoma" element={<Invoice />} />
        <Route path="/invoice/:Enq_Id/:Facility" element={<Invoice />} />




        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Route>
      <Route path="/enquiry" element={<AddCustomer />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;