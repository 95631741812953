import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import UploadCertificate from '../Cetificate/UploadCertificate'
import ApproveRejectDoc from './ApproveRejectDoc'
import UploadDocument from "./UploadDocument"
import ApproveRejectPayment from './ApproveRejectPayment'
import UpdateDocumentByFac from './UpdateDocumentByFac'
import SetQuotation from './SetQuotation'
import QuotationPreview from './QuotationPreview'
import UploadAcknowledgement from './UploadAcknowledgement'
import { formatDate } from "../../components/dateFormate";
import dayjs from "dayjs";

const ViewUser = () => {
  const { userData } = useUser();
  const { Customer_Id, Company_Name, condition } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isModal1, setIsModal1] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [isModal3, setIsModal3] = useState(false);
  const [isModal4, setIsModal4] = useState(false);
  const [isModal5, setIsModal5] = useState(false);
  const [isModal6, setIsModal6] = useState(false);
  const [isModal7, setIsModal7] = useState(false);
  const [Modaldata, setModalData] = useState();
  const [docStatus, setDocStatus] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [filter, setFilter] = useState({
    Customer_Id: Customer_Id? parseInt(Customer_Id):"",
    Company_Name: Company_Name? Company_Name:"",
    Status: condition? condition:"",
    fromDate: dayjs().startOf("month").format('YYYY-MM-DD'),
    toDate: dayjs().endOf("month").format('YYYY-MM-DD')
  });

  console.log(filter);

  const closed = () => {
    setIsModal(false);
    loadStaffData();
  };

  const open = (e) => {
    setModalData(e);
    setIsModal(true);
  };

  const closed1 = () => {
    setIsModal1(false);
    loadStaffData();
  };

  const open1 = (e) => {
    setModalData(e);
    setIsModal1(true);
  };

  const closed2 = () => {
    setIsModal2(false);
    loadStaffData();
  };

  const open2 = (e) => {
    setModalData(e);
    setIsModal2(true);
  };


  const closed3 = () => {
    setIsModal3(false);
    loadStaffData();
  };

  const open3 = (e) => {
    setModalData(e);
    setIsModal3(true);
  };

  const closed4 = () => {
    setIsModal4(false);
    loadStaffData();
  };

  const open4 = (e) => {
    setModalData(e);
    setIsModal4(true);
  };

  const closed5 = () => {
    setIsModal5(false);
    loadStaffData();
  };

  const open5 = (e) => {
    setModalData(e);
    setIsModal5(true);
  };


  
  const closed6 = () => {
    setIsModal6(false);
    loadStaffData();
  };

  const open6 = (e) => {
    setModalData(e);
    setIsModal6(true);
  };


  const closed7 = () => {
    setIsModal7(false);
    loadStaffData();
  };

  const open7 = (e) => {
    setModalData(e);
    setIsModal7(true);
  };

  useEffect(() => {
    loadStaffData();
  }, []);

  const filterData = () => {

    loadStaffData();
}


  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `/getAllCustomerEnq?SrNo=${filter?.Customer_Id}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}&Status=${filter?.Status}`
      );
      const customerDropdown = await axios.get(`/getCustomerForCustomerView`);
      setCustomerData(customerDropdown.data);

      console.log(result.data);
      setStaffData(result.data || []);
      setStaffDataa(result.data || []);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const checkDoc = async (enqId) => {
    if (enqId) {
      const result = await axios.get(`/getCustomerDocumentByEnqId/${enqId}`);
      setDocStatus(prevState => ({
        ...prevState,
        [enqId]: result.data.length > 0
      }));
    }
  };

  useEffect(() => {
    data.forEach(item => {
      checkDoc(item.Enquiry_Sr_No);
    });
  }, [data]);


  const deleteUser = (UserId, Name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(UserId, Name);
      }
    });
  };

  const handleDelete = async (UserId, Name) => {
    const obj = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: Name,
    };
    try {
      await axios.delete(`/deleteCustomerEnquiries/${UserId}`, { data: obj });

      Swal.fire("Deleted!", "Customer Enquiry has been deleted.", "success");
      loadStaffData();
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the user.",
        "error"
      );
    }
  };


  const handleSubmit = async(EnqId, Customer_Id, item) => {
    const validationErrors = {};

    const Data ={
      ...item,
      Customer_Id:Customer_Id
    }
    if (Object.keys(validationErrors).length === 0) {
      showLoader();
     await axios
        .put(`/approveQuotation/${EnqId}`, Data )
        .then((res) => {
            Swal.fire(
                "Success!",
                "Quotation Approved Successfully!!",
                "success")
                .then(
            (result) => {
              if (result.isConfirmed) {
                loadStaffData();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          //error("Server is Busy!!");
          Swal.fire({
            title: "Error",
            text: "Failed to Approve Quotation. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    } 
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => formatDate(row.Added_On),
      sortable: true,
      width:"100px"
    },
    // {
    //   name: "ID",
    //   selector: (row) => row.CustomerId,
    //   sortable: true,
    // },
    {
      name: "Name",
      cell: (row) => <Link to={`/customerProfile/${row.Sr_No}/${row.Enquiry_Sr_No}`}>{`${row.First_Name} ${row.Last_Name}`}</Link>,
width:"169px",
      sortable: true,
    },
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Company_Name}>
          <span
            style={{
              maxWidth: "160px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Company_Name ? row.Company_Name : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
       width:"110px"
    },
    {
      name: "Certificate",
      cell: (row) => (
        <LightTooltip title={row.Certificate_Name}>
          <span
            style={{
              maxWidth: "140px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Certificate_Name ? row.Certificate_Name : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "Remarks",
      cell: (row) => (
        <LightTooltip title={row.Remarks}>
          <span
            style={{
              maxWidth: "100px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Remarks ? row.Remarks : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <span className={`badge ${row.Status === 'Active' ? 'bg-label-success' : row.Status === 'Pending' ? 'bg-label-warning' : 'bg-label-primary'} me-1`}>{row.Status}</span>
      ),
       width:"100px"
    },
  
    {
      name: "",
      cell: (row) => (
        <div className="dropdown">
          <a
            className="p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {(row.Quotation === 'No' && row.Consultation !== '0.00' && row.Net_Amount === '0.00') && ( 
             <Link className="dropdown-item" onClick={() => open6(row)}>
                <i className="fas fa-receipt" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                Quotation Preview
              </Link>)}
          {(row.Consultation === '0.00' ||row.Consultation === null) && (
              <Link className="dropdown-item" onClick={() => open5(row)}>
                <i className="fas fa-sliders-h" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                Set Quotation
              </Link>
            )}
            {(row.Send_Quotation !== null && row.Quotation === 'No') && (
              <Link className="dropdown-item" onClick={() => handleSubmit(row.Enquiry_Sr_No, row.Sr_No, row)}>
                <i className="fas fa-check-square" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                Approve Quotation
              </Link>
            )}
 
            {(row.Quotation === 'Yes' && row.Document_Status !== 'Pending' && row.Net_Amount === '0.00') && (
              <Link className="dropdown-item" to={`/setInstallment/${row.Enquiry_Sr_No}`}>
                <i className="fas fa-user-cog text-primary" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                Set Installment
              </Link>)}
            {row.Net_Amount !== '0.00' && (
              <>     <Link className="dropdown-item" to={`/makePayments/${row.Enquiry_Sr_No}`}>
                <i className="fas fa-cash-register text-success" style={{ fontSize: "18px", marginRight: '5px' }}></i>
                Payment
              </Link>
              <Link className="dropdown-item" to={`/invoice/${row.Enquiry_Sr_No}/Facility`}>
                <i className="fas fa-file-invoice text-info" style={{ fontSize: "18px", marginRight: '5px' }}></i>
                Invoice
              </Link></>
            )}
            {row.Status === 'Active' && (
              <Link className="dropdown-item" onClick={() => open(row)}>
                <i className="ti ti-upload text-info" style={{ fontSize: "18px", marginRight: '5px' }}></i>
                {row.Upload_Certificate? 'Reupload' : 'Upload'}
              </Link>)}
            {row.Document_Status === 'Inprocess' && (
              <Link className="dropdown-item" onClick={() => open1(row)}>
                <i className="fas fa-clipboard-check" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                Verify Documents
              </Link>)}
            {row.Payment_Status === 'Inprocess' && (
              <Link className="dropdown-item" onClick={() => open2(row)}>
                <i className="fas fa-clipboard-check" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                Verify Payment
              </Link>
            )}
            {docStatus[row.Enquiry_Sr_No] && (
              <Link className="dropdown-item" onClick={() => open4(row)}>
                <i className="fas fa-cloud-upload-alt" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                Update Documents
              </Link>
            )}
            {row.Acknowledgement === 'No' && (
            <Link className="dropdown-item" onClick={() => open7(row)}>
                <i className="fas fa-scroll" style={{ fontSize: "16px", marginRight: '5px' }}></i>
                 Upload Acknowledgement
              </Link>)}
            {/* {row.Status !== 'Active' && (
              <Link className="dropdown-item" onClick={(e)=>handleSubmit(e, row.Enquiry_Sr_No, `${row.First_Name} ${row.Last_Name}`)}>
              <CheckIcon className="text-success" style={{ fontSize: "18px" }} />
              Make Active
            </Link>)} */}
            {(row.Quotation === 'Yes' && row.Document_Status === 'Pending') && (
              <Link className="dropdown-item" onClick={() => open3(row)}>
                <i className="ti ti-file-upload text-warning" style={{ fontSize: "18px", marginRight: '5px' }}></i>
                Upload Documents
              </Link>)}
            <Link
              className="dropdown-item"
              onClick={() =>
                deleteUser(row.Enquiry_Sr_No, `${row.First_Name} ${row.Last_Name}`)
              }
            >
              <DeleteIcon
                className="text-danger"
                style={{ fontSize: "20px", marginRight: '5px' }}
              />
              Delete
            </Link>
          </div>
        </div>
      ),
      width: "45px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("Enquiry Details", 20, 10);

    const columns = ["Date", "Customer Id", "Name", "Company Name", "Mobile", "Certificate", "Email", "Address", "Remarks"];

    const rows = data.map((row) => [
      formatDate(row.Added_On),
      row.CustomerId,
      `${row.First_Name} ${row.Last_Name}`,
      row.Company_Name,
      row.Mobile,
      row.Certificate_Name,
      row.Email ? row.Email : "--",
      row.Address ? row.Address : "--",
      row.Remarks ? row.Remarks : "--"
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Enquiry Details.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Date", "Customer Id", "Name", "Company Name", "Mobile", "Certificate", "Email", "Address", 'Remarks'];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        formatDate(row.Added_On),
        row.CustomerId,
        `${row.First_Name} ${row.Last_Name}`,
        row.Company_Name,
        row.Mobile,
        row.Certificate_Name,
        row.Email ? row.Email : "--",
        row.Address ? row.Address : "--",
        row.Remarks ? row.Remarks : "--"
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Enquiry Details");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Enquiry Details.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (row.Email && row.Email.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue))
        ||
        (row.CustomerId && row.CustomerId.toLowerCase().includes(filterValue))
        ||
        (row.Added_On && formatDate(row.Added_On).toLowerCase().includes(filterValue))
        ||
        (row.Certificate_Name && row.Certificate_Name.toLowerCase().includes(filterValue))
        ||
        (row.Company_Name && row.Company_Name.toLowerCase().includes(filterValue))
        ||
        (row.Remarks && row.Remarks.toLowerCase().includes(filterValue))
      );
    });
    setStaffData(filteredData);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Enquiries</h4>
            {/* <div className="page-title">
              <Link to={"/addUser"}>
                <button className="btn btn-primary btn-sm text-center">
                  Add
                </button>
              </Link>
            </div> */}
          </div>
          <div className="card">
                            <h5 className="page-title"></h5>
                            <div className="card-body" style={{ paddingTop: "5px" }}>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                        Company
                                        </label>
                                        <Select
                                            id="example-select"
                                            value={filter.Customer_Id ? { value: filter?.Customer_Id, label: filter?.Company_Name } : null}
                                            options={[
                                                { value: "", label: 'All' },
                                                ...customerData.map((item, index) => ({
                                                    value: item.Sr_No,
                                                    label: item.Company_Name
                                                }))
                                            ]}
                                            onChange={(selectedOption) => {
                                              console.log(selectedOption.value, selectedOption.label)
                                                setFilter({
                                                    ...filter,
                                                    Customer_Id: selectedOption.value,
                                                    Company_Name: selectedOption.label,
                                                });
                                            }}
                                        />

                                    </div>

                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            From Date
                                        </label>
                                        <input type="date" className="form-control"
                                            value={filter.fromDate}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    fromDate: e.target.value,
                                                });
                                            }}></input>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            To Date
                                        </label>
                                        <input type="date" className="form-control"
                                            value={filter.toDate}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    toDate: e.target.value,
                                                });
                                            }}></input>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                        Status
                                        </label>
                                        <Select
                                            id="example-select"
                                            value={filter.Status ? { value: filter?.Status, label: filter?.Status } : null}
                                            options={[
                                                { value: "", label: 'All' },
                                                { value: "Active", label: 'Active' },
                                                { value: "Pending", label: 'Pending' },
                                                { value: "CertificateIssued", label: 'CertificateIssued' },

                                      
                                            ]}
                                            onChange={(selectedOption) => {
                                          
                                                setFilter({
                                                    ...filter,
                                                    Status: selectedOption.value,
                                                   
                                                });
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-md-2">
                                        <button className="btn btn-sm btn-primary" onClick={filterData} style={{ width: "100%" }}>Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
          <div className="card">
            <div className="card-body">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
        </div>

        {isModal && (
          <>
            <UploadCertificate
              open={isModal}
              onClose={closed}
              Modaldata={Modaldata}
            />
          </>
        )
        }
        {isModal1 && (
          <>
            <ApproveRejectDoc
              open={isModal1}
              onClose={closed1}
              updateData={Modaldata}
            />
          </>
        )
        }


        {isModal2 && (
          <>
            <ApproveRejectPayment
              open={isModal2}
              onClose={closed2}
              updateData={Modaldata}
            />
          </>
        )
        }

        {isModal3 && (
          <UploadDocument
            open={isModal3}
            onClose={closed3}
            updateData={Modaldata}
            Role={'Facility'}
          />
        )}

        {isModal4 && (
          <UpdateDocumentByFac
            open={isModal4}
            onClose={closed4}
            updateData={Modaldata}
          />
        )}
        {isModal5 && (
          <SetQuotation
            open={isModal5}
            onClose={closed5}
            updateData={Modaldata}
          />
        )}
        {isModal6 && (
          <QuotationPreview
            open={isModal6}
            onClose={closed6}
            updateData={Modaldata}
          />
        )}
        {isModal7 && (
          <UploadAcknowledgement
            open={isModal7}
            onClose={closed7}
            updateData={Modaldata}
          />
        )}
        {loader}
      </div>
    </div>
  );
};
export default ViewUser;
