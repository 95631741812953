import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "./axios";
import useFullPageLoader from "./useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "./UserContext";
import Select from "react-select"
import PreviewDocumntes from "./PreviewDocumntes";



const Apply = ({ open, onClose, customerData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [certificateOption, setCertificateType] = useState([]);
    const [IsModal, setIsModal] = useState(null);

console.log(customerData);
    const showModal = () => {
        setIsModal(true);
        //handleClose();
      };
      const closed = () => {
        setIsModal(false);
      };

    console.log(userData);
  
    const [customer, setCustomer] = useState({
        Certificate_Id: "",
        Certificate_Name: "",
        Remarks: ""
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


    useEffect(() => {
        fetchData();
    }, [userData]);

    const fetchData = async () => {
        showLoader();
        try {
            const certificateType = await axios.get(`/getCertificateType`);
            const Option = certificateType.data.map((option) => ({
                label: option.Certificate_Name,
                value: option.Sr_No,
            }));
            setCertificateType(Option);
            // if (userData?.UserId) {
            //     const result = await axios.get(`/getCustomerById/${userData?.UserId}`);
            //     console.log(result.data);
            //     setCustomer(result.data);
            // }
        } catch (error) {
            console.error("Error fetching:", error);
        }
        hideLoader();
    };


    const UploadCustomerHandle = async (event) => {
        event.preventDefault();

       
        
        const obj={
            ...customer,
            Role:userData.Role,
            Email:userData.Role? customerData.Email:userData.Email,
            Customer_Id:userData.Role? customerData.Sr_No:userData.UserId,
            Customer_Name:userData.Role? `${customerData.First_Name} ${customerData.Last_Name}`: `${userData.First_Name} ${userData.Last_Name}`,
          Applied_By:`${userData.First_Name} ${userData.Last_Name}`
        }

        showLoader();
       await axios
            .post(`/addCustomerEnquiry`, obj)
            .then((res) => {
                Swal.fire("Success!", "Applied Successfully.", "success");
                
                if(userData?.Role){
                    navigate('/viewEnquiry');
                }{
                    handleClose();
                }
                    
                  
                hideLoader();
               //showModal();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Apply. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >   <Modal.Body>
                <h5>Apply Certificate</h5>
                <hr className="p-0 mb-2" />
                <form onSubmit={UploadCustomerHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="Bank" className="col-form-label">
                                    Certificate Type<span style={{ color: "red" }}>*</span>
                                </label>
                                <Select type="text" name="Bank" id="Bank" className="selecting"
                                    options={certificateOption} required
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            color: state.isSelected ? 'white' : 'black',
                                            backgroundColor: state.isFocused ? 'blue' : 'white',
                                            '&:hover': {
                                                backgroundColor: 'blue', 
                                                color: 'white', 
                                            },
                                        }),
                                    }}
                                    onChange={(e) =>
                                        setCustomer({
                                            ...customer,
                                            Certificate_Id: e.value,
                                            Certificate_Name: e.label,
                                        })
                                    }
                                />
                                <p className="text-danger">{errors.Certificate_Name}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="Remarks" className="col-form-label">
                                    Remarks
                                </label>
                                <textarea
                                    type="Date"
                                    id="Remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    onChange={(e) =>
                                        setCustomer({
                                            ...customer,
                                            Remarks: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <p className="text-danger">{errors.Remarks}</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 text-center">
                            <button className="btn btn-primary btn-sm mt-2" type="submit">
                                Apply
                            </button>
                        </div>
                    </div>
                </form>
                {IsModal && (
        <PreviewDocumntes
          open={IsModal}
          onClose={closed}
          customer={customer}
        />
      )}
                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default Apply;
