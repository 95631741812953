import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { apiUrl } from "../../components/axios";




const UpdateDocumentByFac = ({ open, onClose, updateData, Role }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [check, setCheck] = useState();



    console.log(updateData)

    const [customer, setCustomer] = useState({
        Documents: [],
        Customer_Id: updateData.Sr_No,
        Customer_Name: `${updateData.First_Name} ${updateData.Last_Name}`

    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    console.log(customer?.Documents);

    useEffect(() => {
        (async () => {
            showLoader();
            try {
   
                if (updateData) {
                    const response = await axios.get(`/getCustomerDocumentByEnqId/${updateData?.Enquiry_Sr_No}`);
                    setCustomer((customer) => ({
                        ...customer,
                        Documents: response.data,
                    }));
                    console.log(response.data);
                }
            } catch (error) {
                console.error("Error fetching:", error);
            }
            hideLoader();
        })();
    }, [userData, updateData]);


    const UploadCertificateHandle = async (event) => {
        event.preventDefault();

        // Construct FormData object and append data
        const formdata = new FormData();
        //formdata.append("Documents", JSON.stringify(customer.Documents));
        customer.Documents.forEach(document => {
            formdata.append("Sr_No", document.Sr_No);
                    formdata.append("Doc_Type", document.Doc_Type);
                    formdata.append('Document_Name', document.Document_Name);
                
            
        });

        formdata.append("Customer_Id", customer.Customer_Id);
        formdata.append("Customer_Name", customer.Customer_Name);
        formdata.append("Certificate_Name", updateData.Certificate_Name);

        showLoader();

        // Send PUT request
     await   axios
            .put(`/updateAllCustomerDocumentByFac/${updateData.Enquiry_Sr_No}`, formdata)
            .then((res) => {
                handleClose();
                Swal.fire("Success!", "Documents Updated Successfully.", "success");
                hideLoader();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Updated. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };



    const handleDocumentChange = (index, field, Doc_Id, file) => {
        const newDocuments = Array.isArray(customer.Documents) ? [...customer.Documents] : [];
        if (!newDocuments[index]) {
            newDocuments[index] = {};
        }

        // Check if the file size is less than 2MB
        if (file && file.size && file.size > 2 * 1024 * 1024) {
            Swal.fire({
                title: "Warning",
                text: "File size exceeds 2MB limit. Please select a smaller file.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return;
        }

        // File size is acceptable, continue adding to FormData
        newDocuments[index][field] = { Doc_Id, file };
        setCustomer({
            ...customer,
            Documents: newDocuments
        });
    };


    const handleChange = (index, docType, srNo, file) => {
        // Make a copy of the current state
        const updatedDocuments = [...customer.Documents];
    
        // Check if the index is out of bounds and pad with empty objects if necessary
        while (updatedDocuments.length <= index) {
            updatedDocuments.push({});
        }
    
        // Validate file size
        if (file && file.size && file.size > 2 * 1024 * 1024) {
            Swal.fire({
                title: "Warning",
                text: "File size exceeds 2MB limit. Please select a smaller file.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return;
        }
    
        // Update the document at the specified index
        updatedDocuments[index] = {
            ...updatedDocuments[index],
            Document_Name: file
        };
    
        // Update the state with the new Documents array
        setCustomer({
            ...customer,
            Documents: updatedDocuments
        });
    };
    

    const handleDownloadAllDocuments = async () => {
        if (!customer.Documents || customer.Documents.length === 0) {
            alert('No Documents Found!!');
            return;
        }

        const zip = new JSZip();
        const promises = [];

        customer.Documents.forEach(document => {
            const fileName = document.Document_Name;
            const fileUrl = `/downloadDoc/${fileName}`;

            // Push each axios.get request promise to an array
            promises.push(
                axios.get(fileUrl, { responseType: 'arraybuffer' })
                    .then(response => {
                        zip.file(fileName, response.data, { binary: true });
                    })
                    .catch(error => {
                        console.error('Error fetching document:', error);
                    })
            );
        });

        // Wait for all requests to complete
        Promise.all(promises)
            .then(() => {
                // Generate the zip file after all requests are done
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        FileSaver.saveAs(blob, `${data.First_Name} ${data.Last_Name} - ${updateData.Certificate_Name}.zip`);
                    })
                    .catch(error => {
                        console.error('Error generating zip file:', error);
                    });
            });
    };




    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Update Documents - {updateData.Certificate_Name}</h5>
                    <div className="col-md-6 text-end d-none">
                        <div className="mb-3">
                            <span className="text-dark">Download All Documents Zip : </span>
                            {customer.Documents.length > 0 ? (
                                <>
                                    <a style={{ color: "blue", cursor: 'pointer' }} download={true} onClick={handleDownloadAllDocuments} className="cursor-pointer">
                                        <i className="ti ti-download"></i>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <i className="ti ti-download-off"></i>
                                </>
                            )}
                        </div>
                    </div>
                </div>


                <hr className="p-0 mb-1" />
                <form onSubmit={UploadCertificateHandle}
                >
                <div className="row">
                    {customer.Documents?.map((row, index) => (
                        <>
                            <div className="col-md-3" key={index}>
                                <div className="mb-3">
                                    <label htmlFor={`${row.Doc_Type}_${index}`} className="form-label">
                                        {row.Doc_Type}{" "}<sapn className="text-danger">{`${row.Status ==='Reject'? '(Reject)':''}`}</sapn>
                                    </label>
                                    <input
                                        type="file"
                                        id={`${row.Doc_Type}_${index}`}
                                        className="form-control"
                                        accept=".jpg, .jpeg, .png, .pdf, .docx, .doc, .xls, .xlsx"
                                        onChange={(e) => {
                                            seterrors({});
                                            handleChange(index, `${row.Doc_Type}`, `${row.Sr_No}`, e.target.files[0]);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 mt-4" key={index}>
                                {row.Document_Name ? (
                                    <>
                                        <a
                                            style={{ color: "blue", cursor: 'pointer' }}
                                            href={`${apiUrl}/viewDoc/${row.Document_Name}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="cursor-pointer"
                                        >
                                            <i className="ti ti-eye"></i>
                                        </a>
                                        /
                                        <a
                                            style={{ color: "blue", cursor: 'pointer' }}
                                            href={`${apiUrl}/downloadDoc/${row.Document_Name}`}
                                            download={true}
                                            className="cursor-pointer"
                                        >
                                            <i className="ti ti-download"></i>
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <i className="ti ti-eye-off"></i> / <i className="ti ti-download-off"></i>
                                    </>
                                )}
                            </div>
                        </>
                    ))}
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                            <button type="submit" className="btn btn-md btn-primary ">
                                Update
                            </button>
                        </div>
                    </div>
                </form>

                {loader}
            </Modal.Body>

            <div className="text-danger p-3">
                Note: Upload documents less than 2MB in size and please ensure that the files are in jpg, jpeg, png, doc, docx, xls, xlsx or pdf formats.
            </div>

        </Modal>
    );
}
export default UpdateDocumentByFac;
