import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";




const UploadCertificate = ({ open, onClose, updateData, Installment_Id}) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();

   //console.log(updateData)

    const [upload, setUpload] = useState({
        Payment_Doc: ""
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


    useEffect(() => {
        loadData();
    }, [updateData]);

    const loadData = async () => {
        showLoader();
        try {
            axios.get(`/getEnquiry/${updateData}`).then((res) => {

                console.log(res.data)
                seterrors(res.data[0]);
            });

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };



    const UploadPaymentReceiptHandle = async (event) => {
        event.preventDefault();
   
     
        const formdata = new FormData();
        formdata.append("Payment_Doc", upload.Payment_Doc);

        showLoader();

        // Send PUT request
        axios
            .put(`/updatePaymentDoc/${Installment_Id}/${updateData}`, formdata)
            .then((res) => {
                Swal.fire("Success!", "Payment Receipt Uploaded Successfully.", "success");
                handleClose();
                hideLoader();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Upload. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="md"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <h5>Upload Payment - {`(${errors.Certificate_Name})`}</h5>
                <hr className="p-0 mb-2" />
                <form onSubmit={UploadPaymentReceiptHandle} >
                    <div className="row justify-content-center">
                        <div className="col-md-12">

                            <div className="mb-3">
                                <label htmlFor="Payment_Doc" className="form-label">
                                    Payment Receipt
                                </label>
                                <input
                                    type="file"
                                    id="Payment_Doc"
                                    className="form-control"
                                    capture="false"
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    onChange={(e) => {
                                        setUpload({
                                            ...upload,
                                            Payment_Doc: e.target.files[0]
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                            <button type="submit" className="btn btn-md btn-primary ">
                                Upload
                            </button>
                        </div>
                    </div>
                </form>

                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default UploadCertificate;
