import * as React from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const CertificateType = () => {
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Sr_No, setId] = useState("");
  const [Certificate_Name, setName] = useState("");
  const [Certificate_Duration, setDuration] = useState("");
  const [Annual_Return, setAnnualReturn] = useState("Yes");
  const [Email_Body, setEmailBody] = useState("");
  const [HSN, setHSN] = useState("");
  const [Email_Subject, setEmailSubject] = useState("");
  const [data, setData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    if (userData && userData.Org_Id) {
      Load();
    }
  }, [userData]);

  const Load = async () => {
    showLoader();
    const result = await axios.get(
      `/getCertificateType`
    );
    const sortedData = result.data;
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result;
  };

  async function save(event) {
    event.preventDefault();

    debugger;
    const error = {};

    if (!Certificate_Name) {
      error.Certificate_Name = "Enter Certificate Type";
    }
    
    if (!Certificate_Duration) {
      error.Certificate_Duration = "Enter Certificate Duration";
    }

    if (Object.keys(error).length === 0) {
      try {
        setLoading(true);
        await axios.post(`/addCertificateType`, {
          Certificate_Name: Certificate_Name,
          Certificate_Duration: Certificate_Duration,
          Email_Body: Email_Body,
          Email_Subject: Email_Subject,
          Annual_Return: Annual_Return,
          HSN:HSN,
          Org_Id: userData.Org_Id,
          User_Role: userData.Role,
          UserName: userData.Username,
          Added_By_Id: userData.UserId,
          Added_By: `${userData.First_Name} ${userData.Last_Name}`,
        });
        Swal.fire("Success!", "Certificate Type has been Added.", "success");
        setError("");
        setName("");
        setDuration("");
        setId("");
      setHSN("");
        setAnnualReturn("Yes");
        setEmailBody("");
        setEmailSubject("");
        Load();
        setLoading(false);
      } catch (err) {
        Swal.fire("Server Timeout", "There was a timeout while communicating with the server.", "error");
        setLoading(false);
      }
    } else {
      setError(error);
    }
  }

  async function editVouchers(data) {
    setName(data.Certificate_Name);
    setDuration(data.Certificate_Duration);
    setId(data.Sr_No);
    setEmailBody(data.Email_Body);
    setHSN(data.HSN);
    setAnnualReturn(data.Annual_Return);
    setEmailSubject(data.Email_Subject);
    setEditingItem(data);
  }

  async function update(event) {
    event.preventDefault();

    try {
      const updatedData = {
        Sr_No: Sr_No,
        Certificate_Name: Certificate_Name,
        Certificate_Duration: Certificate_Duration,
        Email_Body: Email_Body,
        HSN:HSN,
        Email_Subject: Email_Subject,
        Annual_Return:Annual_Return,
        Org_Id: userData.Org_Id,
        User_Role: userData.Role,
        UserName: userData.Username,
        Added_By_Id: userData.UserId,
        Added_By: `${userData.First_Name} ${userData.Last_Name}`
      };
      setLoading(true);
      await axios.put(`/updateCertificateType/` + Sr_No, updatedData);
      setData((prevData) =>
        prevData.map((data) =>
          data.Sr_No === Sr_No ? { ...data, ...updatedData } : data
        )
      );
      setName("");
      setDuration("");
      setId("");
      setHSN("");
      setEditingItem(null);
      setAnnualReturn("Yes");
      setEmailBody("");
      setEmailSubject("");
      Load();
      Swal.fire("Success!", "Certificate Type has been updated.", "success");
      setLoading(false);
    } catch (err) {
      Swal.fire("Server Timeout", "There was a timeout while communicating with the server.", "error");
      setLoading(false);
    }
  }

  const deleteUser = (Sr_No, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No, name);
      }
    });
  };

  async function handleDelete(Sr_No, name) {
    const obj = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: name
    }
    await axios.delete(`/deleteCertificateType/${Sr_No}`, { data: obj });
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Certificate Type", 20, 10);

    const columns = ["Certificate Type", "Duration", "Annual Return", "HSN"];

    const rows = records.map((row) => [row.Certificate_Name, row.Certificate_Duration, row.Annual_Return, row.HSN]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Certificate Type.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Certificate Type", "Duration", "Annual Return", "HSN"];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([row.Certificate_Name, row.Certificate_Duration, row.Annual_Return, row.HSN]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Certificate Type");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Certificate Type.xlsx");
  };

  const [records, setRecords] = useState(data);

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        row.Certificate_Name &&
        row.Certificate_Name.toLowerCase().includes(filterValue) ||
        row.Certificate_Duration &&
        row.Certificate_Duration.toLowerCase().includes(filterValue)||
        row.Annual_Return &&
        row.Annual_Return.toLowerCase().includes(filterValue)||
        row.HSN &&
        row.HSN.toLowerCase().includes(filterValue)
      );
    });
    setRecords(filteredData);
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Certificate Type</h5>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <div
                className="row"
              >
                <div className="col-md-4">
                  <div className="mb-2">
                    <label htmlFor="Certificate_Name" className="form-label">
                      Certificate Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="Certificate_Name"
                      className="form-control"
                      placeholder="Enter Certificate Type"
                      required
                      value={Certificate_Name}
                      onChange={(event) => {
                        setName(event.target.value);
                        setError("");
                      }}
                    />
                    <p className="text-danger">{error.Certificate_Name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label htmlFor="Certificate_Duration" className="form-label">
                      Certificate Duration<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="Certificate_Duration"
                      className="form-control"
                      placeholder="Enter Certificate Duration (in Years)"
                      required
                      value={Certificate_Duration}
                      onChange={(event) => {
                        const input = event.target.value;
                        const numericInput = input.replace(/[^0-9.]/g, "");
                        setDuration(numericInput);
                        setError("");
                      }}
                    />
                    <p className="text-danger">{error.Certificate_Duration}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label htmlFor="Annual_Return" className="form-label">
                      Annual Return<span style={{ color: "red" }}>*</span>
                    </label>
                    <select className="form-select"
                      value={Annual_Return || ""}
                      required
                      onChange={(e) =>
                        setAnnualReturn(e.target.value)
                      } >
                      <option label="Yes" value="Yes"></option>
                      <option label="No" value="No"></option>
                    </select>
                    <p className="text-danger">{error.Annual_Return}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-2">
                    <label htmlFor="HSN" className="form-label">
                    HSN Code
                    </label>
                    <input
                      type="text"
                      id="HSN"
                      className="form-control"
                      placeholder="Enter HSN Code"
                maxLength={8}
                      value={HSN}
                      onChange={(event) => {
                        const input = event.target.value;
                        const numericInput = input.replace(/[^0-9]/g, "");
                        setHSN(numericInput);
                        setError("");
                      }}
                    />
                    <p className="text-danger">{error.HSN}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-2">
                    <label htmlFor="Email_Subject" className="form-label">
                    Email Subject
                    </label>
                    <input
                      type="text"
                      id="Email_Subject"
                      className="form-control"
                      placeholder="Enter Email Subject"
                    
                      value={Email_Subject}
                      onChange={(event) => {
                        setEmailSubject(event.target.value);
                        setError("");
                      }}
                    />
                    <p className="text-danger">{error.Email_Subject}</p>
                  </div>
                </div>
                <div className="col-md-12">
                <div className="mb-4">
                <label htmlFor="Annual_Return" className="form-label">
                     Email Body
                    </label>
                <CKEditor
                editor={ClassicEditor}
                data={Email_Body}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setEmailBody(data);
                    console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                }}
            />
                </div></div>
                <div className="row justify-content-center">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    {!isLoading && (
                      <>
                        {editingItem ? (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                            onClick={update}
                          >Update
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                            onClick={save}
                          >
                            Add
                          </button>
                        )}
                      </>
                    )}
                    {isLoading && (
                      <>
                        {editingItem ? (
                          <button
                            className="btn btn-primary mb-1"
                            type="button"
                            disabled
                            //onClick={update}
                          >
                            Updating...
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary mb-1"
                            type="button"
                            disabled
                            //onClick={save}
                          >
                            Adding...
                          </button>
                        )}
                      </>
                    )}
                  </div></div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">

              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <Box height={14} />

              <TableContainer
                className=""
                sx={{
                  maxHeight: "100%",
                  minHeight: "175px",
                  zIndex: 0,
                  position: "relative",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ minWidth: "100PX" }}>
                        Certificate Type
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "100PX" }}>
                        Duration (in Years)
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "100PX" }}>
                      Annual Return
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "100PX" }}>
                      HSN Code
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ zIndex: 0, position: "relative" }}>
                    {records
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            style={{ height: "30px" }}
                          >
                            <TableCell align="left" style={{ padding: "5px", paddingLeft: "20px" }}>
                              {row.Certificate_Name}
                            </TableCell>
                            <TableCell align="left" style={{ padding: "5px", paddingLeft: "20px" }}>
                              {row.Certificate_Duration}
                            </TableCell>
                            <TableCell align="left" style={{ padding: "5px", paddingLeft: "20px" }}>
                              {row.Annual_Return}
                            </TableCell>
                            <TableCell align="left" style={{ padding: "5px", paddingLeft: "20px" }}>
                              {row.HSN}
                            </TableCell>
                            <TableCell
                              className="text-cneter"
                              style={{ padding: "5px" }}
                            >
                              <div className="dropdown">
                                <a
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i>
                                    <MoreVertIcon
                                      style={{
                                        color: "grey",
                                        fontSize: "18px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </i>
                                </a>
                                <div
                                  className="dropdown-menu"
                                  x-placement="bottom-start"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => editVouchers(row)}
                                  >
                                    <EditIcon
                                      className="text-info"
                                      style={{ fontSize: "18px" }}
                                    />{" "}
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => deleteUser(row.Sr_No, row.Certificate_Name)}
                                  >
                                    <DeleteIcon
                                      className="text-danger"
                                      style={{ fontSize: "18px" }}
                                    />
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="mt-3"
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={records.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          {loader}
        </div>
      </div>{" "}
    </div>
  );
};

export default CertificateType;
