import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { formatDate } from "../../components/dateFormate";



const UploadCertificate = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [custDocumentName, setCustDocument] = useState([]);
    const [checkedStatus, setCheckedStatus] = useState({});


    const handleCheckboxChange = (e, index) => {
        const newCustDocumentName = [...custDocumentName];
        const documentToUpdate = newCustDocumentName[index];

        // Update the document status
        documentToUpdate.Payment_DocStatus = e.target.checked ? "Approved" : "Reject";

        // // Update additional fields with data from updateData
        // documentToUpdate.Mobile = updateData.Mobile;
        // documentToUpdate.Certificate_Name = updateData.Certificate_Name;

        // Set the updated array and checked status
        setCustDocument(newCustDocumentName);
        setCheckedStatus({ ...checkedStatus, [documentToUpdate.Sr_No]: e.target.checked });
    };


    console.log(updateData)

    console.log(custDocumentName)


    const handleClose = () => {
        setShow(false);
        onClose();
    };



    useEffect(() => {
        loadData();

    }, [updateData]);

    const loadData = async () => {
        showLoader();
        try {
            await axios.get(`/getInstallmentForPayment/${updateData?.Enquiry_Sr_No}`).then((res) => {

                
                setCustDocument(res.data);
                setCustDocument(prevState =>
                    prevState.map(item => ({
                      ...item,
                      Mobile: updateData?.Mobile,
                      Certificate_Name: updateData?.Certificate_Name
                    }))
                  );
                  console.log(res.data);
            });
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };


    const UploadCertificateHandle = async (event) => {
        event.preventDefault();


        showLoader();


       await axios
            .put(`/updatePaymentStatus`, custDocumentName)
            .then((res) => {
                handleClose();
                Swal.fire("Success!", "Payment Document Verified Successfully.", "success");
                hideLoader();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Verify. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Verify Payment Document - {`(${updateData?.First_Name} ${updateData?.Last_Name} for ${updateData?.Certificate_Name})`}</h5>

                </div>


                <hr className="p-0 mb-1" />
                <form onSubmit={UploadCertificateHandle} >
                    <div className="row justify-content-center mt-4">
                        <table
                            className="table table-bordered table-hover table-responsive"
                            cellSpacing="0"
                            cellPadding="4"
                            align="Center"
                            rules="cols"
                            style={{
                                color: "Black",
                                backgroundColor: "White",
                                borderColor: "#DEDFDE",
                                borderWidth: "1px",
                                borderStyle: "None",
                                borderCollapse: "collapse",
                            }}
                        >
                            <thead>
                                <tr align="center">
                                    <th scope="col">Approve/Reject</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Due Date</th>

                                    <th scope="col">Payment Status</th>

                                    <th scope="col">View/Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {custDocumentName.length === 0 ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            Document not found!
                                        </td>
                                    </tr>
                                ) : (
                                    custDocumentName.map((item, index) => (
                                        <tr key={index}>
                                            <td className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={checkedStatus[item.Sr_No]}
                                                    onChange={(e) => handleCheckboxChange(e, index)}
                                                />
                                            </td>
                                            <td className="text-center">
                                                {item.Amount}
                                            </td>
                                            <td className="text-center">
                                                {formatDate(item.Due_Date)}
                                            </td>
                                            <td className="text-center">
                                                {item?.Payment_Status}
                                            </td>
                                            <td className="text-center">
                                                {item.Payment_Doc ? (
                                                    <>
                                                        <a
                                                            style={{ color: "blue", cursor: 'pointer' }}
                                                            href={`${apiUrl}/viewPaymentDoc/${item.Payment_Doc}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="cursor-pointer"
                                                        >
                                                            <i className="ti ti-eye"></i>
                                                        </a>
                                                        {" / "}
                                                        <a
                                                            style={{ color: "blue", cursor: 'pointer' }}
                                                            href={`${apiUrl}/downloadPaymentDoc/${item.Payment_Doc}`}
                                                            download={true}
                                                            className="cursor-pointer"
                                                        >
                                                            <i className="ti ti-download"></i>
                                                        </a>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="ti ti-eye-off"></i> / <i className="ti ti-download-off"></i>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>

                        </table>

                    </div>

                    <div className="col-md-12 text-center mt-3">
                        <button type="submit" className="btn btn-md btn-primary ">
                            Verify
                        </button>
                    </div>
                </form>

                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default UploadCertificate;
