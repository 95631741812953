import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';



const UploadCertificate = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [check, setCheck] = useState();

    console.log(updateData)

    const [customer, setCustomer] = useState({
        Government: "",
        Consultation: ""
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
    
        const validationErrors = {};
        
        if (Object.keys(validationErrors).length === 0) {
          showLoader();
         await axios
            .post(`/setQuotation/${updateData.Enquiry_Sr_No}`, customer)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Quotation Set Successfully!!",
                    "success")
                    .then(
                (result) => {
                  if (result.isConfirmed) {
                    handleClose();
                  }
                }
              );
              hideLoader();
            })
            .catch((err) => {
              //error("Server is Busy!!");
              Swal.fire({
                title: "Error",
                text: "Failed to set Quotation. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
              });
              hideLoader();
            });
        } else {
          setErrors(validationErrors);
        }
      };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Set Quotation - ({updateData.Certificate_Name||""})</h5>
                    <div className="col-md-6 text-end d-none">
                        {/* <div className="mb-3">
                            <span className="text-dark">Download All Documents Zip : </span>
                            {customer.Documents.length > 0 ? (
                                <>
                                    <a style={{ color: "blue", cursor: 'pointer' }} download={true} onClick={handleDownloadAllDocuments} className="cursor-pointer">
                                        <i className="ti ti-download"></i>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <i className="ti ti-download-off"></i>
                                </>
                            )}
                        </div> */}
                    </div>
                </div>
                <hr className="p-0 mb-1" />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="Government" className="form-label">
                      Government Charges<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Government"
                        className="form-control"
                        placeholder="Enter Government Charges"
                        value={customer.Government}
                        required
                        onChange={(e) =>{
                            const input = e.target.value;
                            let numericInput = input.replace(/[^0-9.]/g, "");
                            setCustomer({
                            ...customer,
                            Government: numericInput,
                          })
                        }
                         
                        }
                      />
                      <p className="text-danger">{errors.Government}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="Consultation" className="form-label">
                      Consultation Charges<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Consultation"
                        className="form-control"
                        placeholder="Enter Consultation Charges"
                        required
                        value={customer.Consultation}
                        onChange={(e) =>
                        {
                            const input = e.target.value;
                            let numericInput = input.replace(/[^0-9.]/g, "");
                            setCustomer({
                            ...customer,
                            Consultation: numericInput,
                          })
                        }
                   
                        }
                      />
                      <p className="text-danger">{errors.Consultation}</p>
                    </div>
                  </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                            <button type="submit" className="btn btn-md btn-primary ">
                            Set Quotation
                            </button>
                        </div>
                    </div>
                </form>

                {loader}
            </Modal.Body>

            {/* <div className="text-danger p-3">
                Note: Upload documents less than 2MB in size and please ensure that the files are in jpg, jpeg, png, doc, docx, xls, xlsx or pdf formats.
            </div> */}

        </Modal>
    );
}
export default UploadCertificate;
